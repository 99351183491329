import { UserInfoService } from 'src/app/services/userInfo/user-info.service';
import { LoginService } from './../../../services/loginService';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class RemenberGuard implements CanActivate {

  constructor(
    private cookie: CookieService,
    private loginSvc: LoginService,
    private router: Router,
    private infoUserSvc: UserInfoService
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let data = this.cookie.get('data');
    if(data){
      let decrypt = CryptoJS.AES.decrypt(data,'data').toString(CryptoJS.enc.Utf8);
      let user  = JSON.parse(decrypt);
      this.loginSvc.authenticate(user).subscribe(res => {
        if(res.token){
          this.infoUserSvc.initUser(res.user.person);
          localStorage.setItem('identity', JSON.stringify(res.user.user));
        }
      });
      this.router.navigate(['/main-view']);
    }
    return true;
  }

}
