import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AlertaComponent } from "src/app/pages/alerta/alerta.component";
import { Alerta } from "src/app/models/alerta/Alerta";

@Injectable({
  providedIn: 'root'
})
export class AlertaService {

  alerts = [
    {value: 0, name: 'NONE'},
    {value: 1, name: 'NOTIFICATIONS'},
    {value: 2, name: 'ALERT'},
    {value: 3, name: 'ALARM'}
  ]

  constructor(private tooltip: MatSnackBar){}

  openTooltip(alerta: Alerta) {
    this.tooltip.openFromComponent(AlertaComponent, {
      duration: 9000,
      data: alerta
    });
  }

  openSnackAlert(message: string, type: string){
    this.tooltip.open(message, type, {
      duration: 9000,
    });
  }

  getAlerts(): any[]{
    return this.alerts;
  }
}
