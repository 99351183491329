import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/services/loginService';
import { AlertaService } from '@services/alerta/alerta.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private _loginService: LoginService,
    private alerta: AlertaService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let token = localStorage.getItem('id_token');
    if (this._loginService.getIdentity() && !this.tokenExpired(token)) {
      return this._loginService.checkLogin(this.router);
    }
    this.alerta.openSnackAlert('La sesion ha caducado, es necesario iniciar sesion de nuevo','Error');
    this.router.navigate(['/sso/login']);
    return false;
  }

  private tokenExpired(token: string) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }
}
