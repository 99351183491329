import { ApiEndpoint } from './apiEndpoint';
import { environment } from 'src/environments/environment';

export interface ServerEndpoints {
    login: ApiEndpoint;
    logout: ApiEndpoint;
    info: ApiEndpoint;
    restore: ApiEndpoint;
}

//export const serverHost: string = 'https://rts.atmosferia.net';
export const serverHost: string = `${environment.api_url}/api-ats`;
export const serverHost2: string = `${environment.api_url}/api-login`;

export const serverEndpoints: ServerEndpoints = {
    login: { method: 'POST', path: serverHost2+ '/login' },
    logout: { method: 'POST', path: serverHost + '/auth/logout' },
    info: { method: 'GET', path: serverHost + '/auth/info' },
    restore: { method: 'PUT', path: serverHost + '/user/restorePass/' },
};
