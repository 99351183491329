<div class="login h-screen flex justify-center items-center relative">
  <!-- <img class="fondo block object-cover" src="../../../../../assets/img/fondo/fondo_web.jpg" alt="fondo"> -->

  <!-- Contenedor principal donde se muestra el formulario -->
  <div class="rebote static block w-10/12 sm:w-8/12 lg:w-6/12 h-max p-4 lg:ring-2 lg:ring-blue-300 bg-white rounded-lg z-10">
      <a href="login">
          <img src="../../../../assets/img/logos/actiontracker_logo_azul.svg" class="w-8/12 mx-auto">
      </a>
      <div class="block mt-4 w-10/12 mx-auto text-center text-xl sm:text-2xl lg:text-2xl">
          <span class="font-medium">No posee permisos para acceder a esta pantalla. Por favor, pongase en contacto con su administrador.</span>
      </div>
  </div>

</div>
