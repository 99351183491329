import { LoginService } from './../../../services/loginService';
import { CrudService } from './../../../services/crudService';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckTokenGuard implements CanActivate {


  constructor(private router:Router, private loginSvc: LoginService){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let idUser = route.params.idUser;
      let token = route.params.token;
      if(idUser && token && !this.tokenExpired(route.params.token)){
        let result = {token: token}
        this.loginSvc.setSession(result);
        this.setLocalStore(idUser);
        return true;
      }
      this.router.navigate(['/sso/login']);
      return false;
  }

  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  setLocalStore(idUser){
    let identity = {
      idUser: idUser
    }
    localStorage.setItem('info',JSON.stringify(identity));
  }
}
