import { Injectable } from '@angular/core';
import { Preference } from '@models/preference.model';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class I18nServiceService {

  langs = [
    { jsonName: 'es', name: 'Español' },
    { jsonName: 'en', name: 'English' },
    { jsonName: 'cat', name: 'Català' },
    { jsonName: 'fr', name: 'Fránces' },
    { jsonName: 'br', name: 'Portugués brasileño' }
  ];

  constructor(private translate: TranslateService) { }

  getLangs(): any[]{
    return this.langs;
  }

  changeLanguagePreferece(user: any, preferenceCompany: Preference){
    if(user && user.idioma){
      localStorage.setItem('language',user.idioma);
      this.changeLanguage(user.idioma);
    }else if(preferenceCompany && preferenceCompany.idioma){
      localStorage.setItem('language',preferenceCompany.idioma);
      this.changeLanguage(preferenceCompany.idioma);
    }else{
      localStorage.setItem('language','en');
      this.changeLanguage('en');
    }
  }

  changeLanguage(language: string) {
    this.translate.use(language);
  }
}
