<div class="flex flex-col items-center w-full min-h-screen bg-secundary dark:bg-secundary_dark relative">
  <div class="mt-36 lg:mt-24 w-11/12 mb-8">
    <div class="flex justify-center w-full xl:mr-4 xl:px-0">
      <div class="flex items-center justify-center w-full xl:w-max xl:mr-4 xl:px-0">
        <mat-button-toggle-group class="max-w-full rounded-none overflow-auto" name="fontStyle" aria-label="Font Style" [value]="buttonValue">
          <mat-button-toggle *ngFor="let item of options" [routerLink]="item.url" [value]="item.url">
            {{item.txt | translate}}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
  <div class="ml-2 absolute bottom-0 left-0">
    <span class="text-left"><a class="text-blue-400 underline" href="http://actiontracker.eu">Actiontracker.eu</a> {{version}}</span>
  </div>
</div>
