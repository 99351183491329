import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { User } from '../models/user/User';
import { serverEndpoints } from './resources/authEndpoint';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { contentHeaders } from '../headers';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    private username = new Subject<string>();
    public identity;
    private apiUrl = `${environment.API_SERVER}/`;
    public token;
    public authenticationHeaders;

    constructor(
        private _http: HttpClient,
        private router: Router,
        private httpClient: HttpClient,
        private _route: ActivatedRoute,
        private _router: Router) {
          this.authenticationHeaders = new HttpHeaders().set('x-access-token', this.getToken())
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        }

    authenticate(loginInfo: User) {
        return this._http.post<any>(`${serverEndpoints.login.path}`, loginInfo, { headers: contentHeaders }).pipe(
            tap(res => this.setSession(res))
        );
    }

    public checkIfLoggedIn(): Promise<void> {
        /*
                return new Promise((resolve, reject) => {
                    return this.http.request<User>(
                        serverEndpoints.info,
                        { withCredentials: true }
                    ).then(
                        data => {
                            this.username.next(data.name);
                            resolve();
                        }, error => reject(error)
                    );
                });
        */
        return
    }

    public logout(): void {
        localStorage.clear();
        this.identity = null;
        this._router.navigate(['/sso/login'])
    }

    public toLogin(): void {
        this.router.navigate(['/login']);
    }

    // public getUser<T>(): Observable<T[]> {
    //     return this.httpClient.get<T[]>(this.apiUrl + "auth/info", { withCredentials: true });
    // }

    public getUser<T>(userId): Observable<any> {
        return this.httpClient.get<any>(this.apiUrl + "person/get/user/" + userId, {headers: this.authenticationHeaders});
    }

    public getUsername(): Observable<any> {
        return this.username.asObservable();
    }

    public setSession(authResult) {
        localStorage.setItem('id_token', authResult.token);
    }

    getIdentity() {
        let identity = JSON.parse(localStorage.getItem('identity'));
        identity ? this.identity = identity : this.identity = null;
        return identity;
    }

    checkLogin(router) {
        if (this.identity) {
            return true;
        } else {
            router.navigate(['/sso/login']);
        }
    }

    getToken() {
      let token = localStorage.getItem('id_token');
      (token != 'undefined') ? this.token = token : this.token = null;
      return this.token;
  }

}
