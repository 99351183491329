import { Injectable } from '@angular/core';
import zones from 'src/assets/time-zone/time-zone.json';
// import * as moment from 'moment';
import 'moment-timezone';
import moment from 'moment-timezone';

interface TimeZone{
  UTC: string,
  zonaHoraria: string
}

interface formatDate{
  name: string;
  pipe: string;
}

@Injectable({
  providedIn: 'root'
})
export class TimeZoneService {

  private timeZoneList: TimeZone[] = [];
  private timeZoneSelect !: TimeZone;
  private formatDate !: formatDate;

  constructor() {
    moment.tz.setDefault('UTC');// Zona predetermindad - UTC 0
    this.setZoneList();
  }

  setFormatDate(){
    if(localStorage.getItem('formatDate')){
      this.formatDate = JSON.parse(localStorage.getItem('formatDate'));
    }else{
      this.formatDate = {name: 'DD/MM/YYYY', pipe: 'dd/MM/YYYY'};
    }
  }

  setZoneList(){
    const uniqueValues: TimeZone[] = [];
    const uniqueKeys = [];
    zones.forEach(item => {
      if (!uniqueKeys.includes(item.UTC)) {
        uniqueKeys.push(item.UTC);
        uniqueValues.push({UTC: item.UTC, zonaHoraria: item['zona horaria'] });
      }
    });
    this.timeZoneList = uniqueValues;
  }

  getTimeZoneList() : TimeZone[] {
    return this.timeZoneList;
  }

  getTimeZoneSelect() : TimeZone {
    return this.timeZoneSelect;
  }

  setCurrentTimeZone(minTimeZone: number) {
    const zone = this.minutesToZone(minTimeZone);
    this.timeZoneSelect = this.timeZoneList.find(item => item.UTC.includes(zone));
  }

  convertDatetoTimeZone(inpuDate: Date, format:string): string{
    if(this.timeZoneSelect){
      if(format === 'date'){
        return moment(inpuDate).tz(this.timeZoneSelect.zonaHoraria).format(this.formatDate.name);
      }else{
        return moment(inpuDate).tz(this.timeZoneSelect.zonaHoraria).format(this.formatDate.name +' HH:mm');
      }
    }

  }

  minutesToZone(minZone: number) : string{
    if(((minZone/60).toString()).includes('.')){
      let p = ((minZone/60).toString()).split('.');
      let m = parseInt(p[1]);
      if(m == 5){
        if(((minZone/60).toString()).includes('-')){
          return 'UTC'+p[0]+':'+'30';
        }else{
          return 'UTC'+'+'+p[0]+':'+'30';
        }
      }
      if(m == 75){
        if(((minZone/60).toString()).includes('-')){
          return 'UTC'+p[0]+':'+'45';
        }else{
          return 'UTC'+'+'+p[0]+':'+'45';
        }
      }
    }else{
      if(((minZone/60).toString()).includes('-')){
        return 'UTC'+((minZone/60).toString());
      }else{
        return 'UTC'+'+'+((minZone/60).toString());
      }
    }
  }

  zoneToMinutes(zone: TimeZone){
    let timezone:number = 0;
    if(zone?.UTC){
      if(zone.UTC.includes('-')){
        let hour = zone.UTC.split('-')[1];
        if(hour.includes(':')){
          let t = hour.split(':');
          let time = (parseInt(t[0])*60)+parseInt(t[1]);
          timezone = time * -1;
        }else{
          let time = parseInt(hour)*60;
          timezone = time * -1;
        }

      }
      if(zone.UTC.includes('+')){
        let hour = zone.UTC.split('+')[1];
        if(hour.includes(':')){
          let t = hour.split(':');
          let time = (parseInt(t[0])*60)+parseInt(t[1]);
          timezone = time;

        }else{
          let time = parseInt(hour)*60;
          timezone = time;
        }
      }
    }
    return timezone
  }

}
