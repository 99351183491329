import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  private principal: string = '1';
  private production: string = '2';
  private changes: string = '56';

  constructor() { }

  getVersion(){
    return `// Exocare // OnBrain WebApp V${this.principal}.${this.production}.${this.changes}`;
  }

}
