export const globalEnvironment = {
  ms_gateway: {
    methods: {
      users: "user/users",
      taskAll: "task/allTask",
      priorities: "priorities-task/get",
      entities: "type-entities/get",
      roles: "roles/get",
      areas: "areas-task/get",
      markers: "markers-task/get",
      bussiness: "business/get",
    },
  },
  ms_autentication: {
    methods: {},
  },
};
