import { BehaviorSubject } from 'rxjs';
import { CrudService } from './../crudService';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AlertaService } from '@services/alerta/alerta.service';

@Injectable({
  providedIn: 'root',
})
export class RolesPermissionsService {
  public rolUser: any;
  public menuRol: any;
  public menuPadres: any[] = [];
  public ifPerfil: boolean = false;
  private menuTabsBS = new BehaviorSubject<any>('');
  public menuTabs = this.menuTabsBS.asObservable();
  private menuMap = new BehaviorSubject<any>('');
  public $menuMap = this.menuMap.asObservable();

  private superAdmin: boolean = false;
  private indGlobal: boolean = false;


  constructor(private crudSvc: CrudService, private snackBar: MatSnackBar, private router: Router, private alerta: AlertaService) { }


  public getRolUser(idPersona) {
    this.crudSvc.getEntities('rolePerson', { idPersona: idPersona }).then(res => {
      let { rolePersonList } = res.body;

      if (rolePersonList.length === 0) {
        this.rolUser = { idRol: 0, habilitado: false }
        this.alerta.openSnackAlert("El usuario no tiene rol asignado, comuníquese con el administrador", "Error");
        this.router.navigate(['/sso/login']);
        return;
      }

      this.rolUser = rolePersonList.find(rol => rol.habilitado == true);
      this.getMenuRoleUser(this.rolUser.idRol);;
    });;
  }



  getMenuRoleUser(idRole) {
    this.crudSvc
      .getEntities('menuRole', { idRol: idRole, habilitado: true })
      .then((res) => {
        let { menuRole } = res.body;
        menuRole = menuRole.filter((rol) => rol.Menu.appNom == 'web');
        menuRole = menuRole.map(({ ...rol }) => ({
          nomMenu: rol.Menu.nomMenu,
          codMenu: rol.Menu.codMenu,
          ...rol,
        }));
        this.menuRol = menuRole;
        this.getMenuPadres();
        this.menuMap.next(true);
      });
  }

  getMenuPadres() {
    let menuTabs = [
      { txt: 'MAP', url: 'mapa', codMenu: '1MPW', visible: true },
      { txt: 'EVENTS', url: 'alerta', codMenu: '1ETW', visible: true },
      {
        txt: 'ADMINISTRATION',
        url: 'administracion',
        codMenu: '1ADW',
        visible: true,
      },
      { txt: 'RESOURCE', url: 'recursos', codMenu: '1RCW', visible: true },
      { txt: 'RESERVES', url: 'reserves', codMenu: '1RVW', visible: true },
      {
        txt: 'TRAJECTORIES',
        url: 'trayectorias',
        codMenu: '1TYW',
        visible: true,
      },
      {
        txt: 'HARDWARE MANAGEMENT',
        url: 'gestion-hardware',
        codMenu: '1GHW',
        visible: true,
      },
      { txt: 'OPERATIONS', url: 'operations', codMenu: '1OPRW', visible: true },
      { txt: 'STATISTICS', url: 'statistics', codMenu: '1ESW', visible: true },
      { txt: 'HELP', url: 'ayuda', codMenu: '1AYW', visible: true },
      { txt: 'SETTING', url: 'configuracion', codMenu: '1CFW', visible: true },
    ];
    let padres = this.menuRol.filter((rol) => rol.codMenu.includes('1'));
    this.menuPadres = padres;
    padres.find((rol) => rol.codMenu.includes('1PFW'))
      ? (this.ifPerfil = true)
      : (this.ifPerfil = false);
    menuTabs.forEach((menuTab) => {
      let menu = padres.find(
        (rol) => rol.codMenu == menuTab.codMenu && rol.indAcceder == true
      );
      if (!menu) {
        menuTab.visible = false;
      }
    });
    menuTabs = menuTabs.filter((menu) => menu.visible == true);
    this.menuTabsBS.next(menuTabs);
  }

  getMenuHijos(codMenuPadre): any[] {
    let father = this.menuRol.find((menu) => menu.codMenu == codMenuPadre);
    return this.menuRol.filter(
      (menu) => menu?.Menu.idMenuPadre == father.idMenu
    );
  }

  getMenuRol(codMenu) {
    if (codMenu) return this.menuRol.find((menu) => menu.codMenu == codMenu);
  }

  getMenuRolById(idMenu) {
    if (idMenu) return this.menuRol.find((menu) => menu.idMenu == idMenu);
  }

  getRolUserLogin() {
    if (this.rolUser) return this.rolUser;
  }

  getSuperAdmin(codMenu: string) {
    return this.getMenuRol(codMenu)?.indSuperAdmin;
  }

  getIndGlobal(codMenu: string) {
    return this.getMenuRol(codMenu)?.indGlobal;
  }
}
