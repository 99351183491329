import { Injectable, isDevMode } from '@angular/core';

@Injectable()
export class ConsoleInterceptorService {
  constructor() {
    if (isDevMode()) {
      this.disableConsoleLogs();
    }
  }

  disableConsoleLogs() {
    if (typeof console === 'object') {
      for (const logFunc in console) {
        if (console.hasOwnProperty(logFunc)) {
          console[logFunc] = () => { };
        }
      }
    }
  }
}
