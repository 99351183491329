
import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })

export class LocationService {

    private apiUrl = `${environment.API_SERVER}/`;
    private selectedWorker: object;
    private selectedType: any;

    public userPosition= new EventEmitter();

    constructor(private http: HttpClient) { }

    /*
    public getUsersLocations<T>(){
        this.usersLocations=[];
        this.http.get<T[]>(this.apiUrl + "user/getAll", {withCredentials: true}).subscribe(users => {
            let userLocation = [];;
            for (var i=0; i< users.length; i++){
                userLocation = [];
                userLocation.push(users[i]['id'],users[i]['lat'],users[i]['lon']);
                this.usersLocations.push(userLocation);
            }
        });
        return this.usersLocations;
    } */


    public getUserPosition(){
        if (!navigator.geolocation) {
            // Si tenemos acceso.
          } else {
            navigator.geolocation.getCurrentPosition(async (position) => {
               this.userPosition.emit(await [position.coords.latitude, position.coords.longitude])
            }, error => {
                this.userPosition.emit(false)
            })
        }
    }

    public getUsersLocations<T>() {
        return new Promise<any>(async resolve => {
            let arrayOfUserLocations = await this.getArrayOfUsersLocations();
            resolve(arrayOfUserLocations);
        });

    }

    public getArrayOfUsersLocations<T>() {
        return new Promise<any>(resolve => {
            var usersLocations = [];
            this.http.get<T[]>(this.apiUrl + "user/getAll", { withCredentials: true }).subscribe(users => {
                for (var i = 0; i < users.length; i++) {
                    usersLocations[i] = { "id": users[i]['id'], "name": users[i]['name'], "lat": users[i]['lat'], "lon": users[i]['lon'] };
                }
                resolve(usersLocations);
            });
        });
    }

    public setSelectedWorker(worker: object) {
        this.selectedWorker = worker;
    }

    public getSelectedWorker() {
        return this.selectedWorker;
    }

    public setSelectedType(type: object) {
        this.selectedType = type;
    }

    public getSelectedType() {
        return this.selectedType;
    }
}
