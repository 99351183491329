import { CheckTimeTokenGuard } from './pages/change-password/guard/check-time-token.guard';
import { RemenberGuard } from './pages/sso/guard/remenber.guard';
import { CheckTokenGuard } from './pages/map-view/guard/check-token.guard';
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./pages/sso/guard/auth.guard";
import { CookieService } from 'ngx-cookie-service';
import { CustomPreloadService } from './services/custom-preload/custom-preload.service';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: "", redirectTo: "/sso/login", pathMatch: "full" },
  {
    path:'#/:code',
    loadChildren: () => import('./pages/sso/sso.module').then(m => m.SsoModule)
  },
  {
    path: "sso",
    canActivate: [RemenberGuard],
    loadChildren: () => import('./pages/sso/sso.module').then(m => m.SsoModule)
  },
  {
    path: "main-view",
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/main-view/main-view.module').then(m => m.MainViewModule),
    data: {
      preload:true
    }
  },
  {
    path: "map-view/:idUser/:token",
    loadChildren: () => import('./pages/map-view/map-view.module').then(m => m.MapViewModule),
    canActivate: [CheckTokenGuard]
  },
  {
    path: "change-password/:token2",
    canActivate: [CheckTimeTokenGuard],
    loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordModule)
  },
  {
    path: "unauthorized",
    component: UnauthorizedComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    preloadingStrategy: CustomPreloadService
  })],
  exports: [RouterModule],
  providers: [CookieService]
})
export class AppRoutingModule { }
