import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class LogoutInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse && event.body?.logout) {
          this.showLogoutModal();
        }
      })
    );
  }

  // Función para mostrar el modal
  private showLogoutModal(): void {
    const modal = document.getElementById('logoutModal');
    const closeModal = document.getElementById('closeModal');
    const confirmLogout = document.getElementById('confirmLogout');

    if (modal) {
      modal.style.display = 'block'; // Mostrar modal

      confirmLogout?.addEventListener('click', () => {
        modal.style.display = 'none';
        localStorage.clear();
        this.router.navigate(['/sso/login']);
      });

      closeModal?.addEventListener('click', () => {
        modal.style.display = 'none';
      });

      // Cerrar el modal si se hace clic fuera del contenido
      window.addEventListener('click', (event) => {
        if (event.target === modal) {
          modal.style.display = 'none';
        }
      });
    }
  }
}
