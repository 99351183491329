import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searcher'
})

export class SearchPipe implements PipeTransform {

  // items es la lista a filtrar, searchField el campo (Ej: si es persona nombre, si es coche matrícula),
  // searchText el texto que llega desde el input
  transform(items: any[], searchText: string): any[] {
    let filtered = [];
    searchText = searchText.toLocaleLowerCase();
    filtered = items.filter(it => {
      let isThere=false;
      if(it.groupObject['type'] == 'Vehículos'){
        isThere= it.matricula.toLocaleLowerCase().includes(searchText);
      } else if(it.groupObject['type'] == 'Personas'){
        isThere= it.name.toLocaleLowerCase().includes(searchText);
      } else if(it.groupObject['type']== 'Tareas'){
        isThere= it.tarea.toLocaleLowerCase().includes(searchText);
      } else if(it.groupObject['type']== 'Maquinaria'){
        isThere= it.id.toLocaleLowerCase().includes(searchText);
      }
      else {
        isThere= it.id.toLocaleLowerCase().includes(searchText);
      }

      return isThere;

    });

    return filtered;
  }

}
