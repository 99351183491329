import { takeUntil } from 'rxjs/operators';
import { RolesPermissionsService } from './../../services/roles/roles-permissions.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { VersionService } from 'src/app/services/version/version.service';

@Component({
  selector: 'app-alerta',
  templateUrl: './alerta.component.html',
  styleUrls: ['./alerta.component.scss']
})
export class AlertaComponent implements OnInit, OnDestroy {

  options: any[];
  public alert;
  buttonValue: string;
  version: any;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private route: Router,
    private rolPermSvc: RolesPermissionsService,
    private versionSvc: VersionService
  ) {
    this.version = this.versionSvc.getVersion();
    this.buttonValue = this.route.url.split('/')[3];
  }

  ngOnInit(): void {
    this.rolPermSvc.menuTabs.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if(res){
        this.getMenus();
      }
    });
  }

  getMenus(){
    let hijos = this.rolPermSvc.getMenuHijos('1ETW');
    let options = [
      { txt: "ALARM", url: "alarms", codMenu: '2AMW', visible: true },
      { txt: "ALERTS", url: "alerts", codMenu: '2ALW', visible: true  },
      { txt: "NOTIFICATIONS", url: "notification", codMenu: '2NFW', visible: true  },
    ];
    options.forEach(op => {
      let men = hijos.find(menu => menu.codMenu == op.codMenu && menu.indAcceder == true);
      men ? op.visible=true : op.visible=false;
    });
    this.options = options.filter(op => op.visible==true);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
